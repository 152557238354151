import React from "react"

const MenuAddOnItem = ({
  itemId,
  label,
  details,
  selectOptions,
  handleChange,
  price,
}) => {
  return (
    <div className="flex flex-col md:flex-row items-center border justify-between">
      <div className="w-full md:w-1/3">
        <label htmlFor={itemId} className="p-2 label">
          {label}
        </label>
        <p className="text-xs p-2 w-full">{details}</p>
      </div>
      <select
        type="select"
        id={itemId}
        className="p-2 input input-bordered input-primary"
        onChange={handleChange}
      >
        {selectOptions.map((option) => (
            <option value={option.value} key={option.value} >{option.description}</option>
        ))}
      </select>
      <p className="p-2">${price}</p>
    </div>
  )
}

export default MenuAddOnItem
