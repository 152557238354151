import React from "react"

const MenuItem = ({
  itemId,
  label,
  description,
  details,
  handleChange,
  price,
  handleExtra,
  extraMeatPortions,
  extraMeatPrice,
  combinedTotal,
}) => {
  const [extraMeat, setExtraMeat] = React.useState(false)

  return (
    <div className="flex flex-col md:flex-row items-center border p-2">
      <div className="w-full md:w-1/2">
        <label htmlFor={itemId} className="p-2 label">
          {label}
        </label>
        {description && (
          <p className="px-2 pt-2 text-xs font-semibold">{description}</p>
        )}
        {details && <p className="p-2 text-xs">{details}</p>}
      </div>
      <input
        type="number"
        inputMode="numeric"
        min="0"
        id={itemId}
        className="px-2 input input-bordered input-primary input-extrasmall w-1/6"
        onChange={handleChange}
        defaultValue={0}
      />
      <p className="p-2">${price}</p>
      {handleExtra && (
        <div className="flex items-center">
          <input
            type="checkbox"
            className="checkbox"
            id="extraMeat"
            onChange={() => {
              setExtraMeat(!extraMeat)
              handleExtra(0)
            }}
          />
          <label
            htmlFor="extraMeat"
            className="px-2 pt-2 text-xs font-semibold"
          >
            Extra Meat Portions*
          </label>
          {extraMeat && (
            <div className="flex">
              <input
                type="number"
                inputMode="numeric"
                min="0"
                className="px-2 input input-bordered input-primary input-medium w-1/2"
                value={extraMeatPortions}
                onChange={handleExtra}
              />
              <p className="p-2">${extraMeatPrice}</p>
              <p className="p-2">${combinedTotal}</p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MenuItem
